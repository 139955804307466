import React from "react";
import Developer from "./Developers";
import myPic from "../assets/MyPic.jpeg";

function DeveloperCard() {
  return (
    <>
      {/* <h1>CollegeZio Team: </h1> */}
      <div>
        <Developer
          collegeName={"BTech (CSE)"}
          avatarUrl={myPic}
          name={"Mayank Pal"}
          role={"MERN Stack Developer"}
          bio={
            "Enthusiastic and detail-oriented Computer Science Engineering student with a passion for Web Development and a solid foundation in MERN stack technologies, AWS Cloud, Netlify, Git & GitHub."
          }
          socialLinks={"https://www.linkedin.com/in/its-mayank-pal/"}
        ></Developer>
      </div>
      <hr />
    </>
  );
}

export default DeveloperCard;
